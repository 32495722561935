<template>
  <v-form @submit.prevent="login()">
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">
          Login
        </h1>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="username"
          label="Username"
          prepend-icon="mdi-account-circle"
        />
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          v-model="password"
          label="Password"
          autocomplete="on"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" type="submit">Login</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    showPassword: false,
    username: "",
    password: ""
  }),
  created() {
    this.logout();
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    login() {
      this.$store.dispatch("login", {
        username: this.username,
        password: this.password
      });
    }
  }
};
</script>

<style scoped></style>
